<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-import">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Subida Masiva</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
              @click="limpiarModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="btn btn-default image-preview-input col-md-12">
              <p>
                <strong>Subir Archivo </strong>
                <button
                  type="button"
                  style="font-size: 12px"
                  class="btn-sm alert-default-danger"
                  v-if="form.file != null"
                  @click="limpiarModal()"
                >
                  <i class="fas fa-broom"></i> Limpiar
                </button>
              </p>
              <input
                type="file"
                id="file"
                ref="file"
                :class="[
                  $v.form.file.$invalid ? 'is-invalid' : 'is-valid',
                  form.file != null
                    ? 'form-control btn alert-default-success'
                    : 'form-control btn alert-default-warning',
                ]"
                v-on:change="elegirDirectorio()"
                accept=".XLSX, .CSV"
              />
            </div>
          </div>

          <div class="modal-footer">
            <div class="row">
              <button
                type="button"
                class="btn alert-default-primary"
                @click="importExcel()"
                v-show="!$v.form.$invalid"
                v-if="
                  $store.getters.can(
                    'hidrocarburos.guiaCompraSTrans.importExcel'
                  )
                "
              >
                Importar
                <i class="fas fa-cloud-upload-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "GuiaCompraSinTransporteImportExcel",
  components: {},
  data() {
    return {
      form: { file: null },
    };
  },
  validations: {
    form: {
      file: {
        required,
      },
    },
  },
  methods: {
    elegirDirectorio() {
      this.form.file = this.$refs.file.files[0];
    },

    limpiarModal() {
      const fileInput = document.getElementById("file");
      fileInput.value = null;
      this.form.file = null;
    },

    importExcel() {
      this.$parent.cargando = true;
      let formData = new FormData();
      formData.append("file", this.form.file);
      axios
        .post("/api/hidrocarburos/detGuiaCompraSTrans/importExcel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$refs.closeModal.click();
          let result_content =
            response.data.error.length > 0
              ? `<div>
                <p class="text-danger">
                  <strong>Las Guías no se cargarón.</strong>
                </p>
              </div>`
              : `<div>
                <p class="text-success">
                  <strong>Las guías se cargo exitosamente.</strong>
                </p>
              </div>`;
          if (response.data.error.length > 0) {
            result_content += `<h4>Errores encontrados.</h4>`;
            response.data.error.forEach(
              (err) => (result_content += `<div><small>${err}</small></div>`)
            );
          }

          this.$swal({
            title: "Resultado de la carga.",
            html: result_content,
            icon: response.data.error.length > 0 ? "error" : "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
            //width: "800px",
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              this.$parent.getIndex();
              this.limpiarModal();
            }
          });
          this.$parent.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
  },
};
</script>
